import {
  EpisodeMaterialContent,
  EpisodesBlock,
  EpisodeScreenDocument,
  PodcastMaterialContent,
  RootState
} from '@client/types'
import { PlayerStatus } from '@common/AudioPlayer/constants'
import { getMaterialsById } from '@selectors/materialsSelectors'
import { getScreenDocumentsById } from '@selectors/screenDocumentsSelectors'
import { createCachedSelector } from 're-reselect'
import { createSelector } from 'reselect'

export const getAudioPlayer = (state: RootState) => state.audioPlayer
export const getEpisodeDataById = (state: RootState) => state.audioPlayer.byId
export const getPlayingEpisodeUrl = (state: RootState) =>
  state.audioPlayer.playingEpisodeUrl

export const getPlayButtonProps = (
  { audioPlayer: { byId } }: RootState,
  episodeUrl: string
) =>
  byId[episodeUrl]
    ? byId[episodeUrl]
    : { status: PlayerStatus.CLOSED, time: null }

export const getSavedProgress = (state: RootState, url: string) =>
  state.currentUser.episode_history_shelf[url]

export const getListenedState = (state: RootState, url: string) =>
  state.currentUser.episode_history_shelf[url] > 0.75 || false

export const getEpisodeData = createCachedSelector(
  getScreenDocumentsById,
  getMaterialsById,
  getEpisodeDataById,
  (
    state: RootState,
    {
      url,
      podcasts
    }: {
      url: string
      podcasts?: string
    }
  ) => ({ url, podcasts }),
  (screenDocuments, materials, episodes, data) => {
    const { url, podcasts } = data

    if (podcasts) {
      const {
        content: { blocks }
      } = materials[podcasts] as PodcastMaterialContent

      const { data } = blocks.filter(
        (block) => block.type === 'episodes'
      )[0] as EpisodesBlock

      return data.filter((d) => d.url === url)[0]
    }

    if (episodes[url]) {
      const { audio, blocks, image } = episodes[url]

      return {
        audio,
        player: { blocks },
        image
      }
    } else {
      return (
        (materials[url] as EpisodeMaterialContent) ||
        (screenDocuments[url] as EpisodeScreenDocument) ||
        null
      )
    }
  }
)((state: RootState, { url }) => url)

export const getPlayingEpisodeProps = createSelector(
  [getPlayingEpisodeUrl, getEpisodeDataById],
  (playingEpisodeUrl, playerEpisodes) =>
    playingEpisodeUrl
      ? {
          episodeUrl: playingEpisodeUrl,
          data: playerEpisodes[playingEpisodeUrl]
        }
      : null
)
