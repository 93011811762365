import React from 'react'

import styles from './ChaptersButton.module.css'

const ChaptersButton = ({ setIsModalActive }) => {
  const handleClick = () => {
    setIsModalActive(true)
  }
  return (
    <button onClick={handleClick} className={styles.modalButton}>
      Оглавление
    </button>
  )
}

export default ChaptersButton
