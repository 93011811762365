import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Lang, MaterialContent, ShareData } from '@client/types'
import { ToolbarContainer } from '@common/ToolbarContainer'
import { getMaterials } from '@selectors/materialsSelectors'
import {
  fetchTopbarAds,
  topbarAdBecomeVisible,
  topbarAdClicked
} from '@store/Materials/materialsActions'
import { capitalize } from '@utils/capitalize'
import { makeClassName } from '@utils/makeClassName'

import { SvgSymbol } from '@meduza/ui-kit-2'
import { ClassNames } from '@meduza/ui-kit-2/dist/utils/makeClassName'

/*
 * Появляется при скролле материала сверху, содежит кнопки шеров и возможность закрыть материал
 */
import styles from './TopBar.module.css'

interface TopBarProps {
  material: MaterialContent
  lang: Lang
  showContent?: boolean
}

export const TopBar: React.FC<TopBarProps> = ({
  material: {
    url,
    banners,
    layout,
    container: { mods = [] },
    og
  },
  lang,
  showContent
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const ref = useRef<HTMLDivElement>(null)
  const [visible, setVisible] = useState(false)

  const { topbarAds } = useSelector(getMaterials)

  const [adVisibilityTracked, setAdVisibilityTracked] = useState(false)

  const handleAdClick = () => {
    if (topbarAds.url) {
      dispatch(topbarAdClicked(topbarAds.url))
    }
  }

  const handleScroll = () => {
    if (ref.current) {
      const elRect = ref.current.getBoundingClientRect()
      const bodyRect = document.body.getBoundingClientRect()
      const elTopOffset = elRect.top - bodyRect.top
      const scrollTop =
        Math.abs(
          window.scrollY ||
            window.pageYOffset ||
            document.body.scrollTop ||
            document.documentElement.scrollTop
        ) + 1
      setVisible(scrollTop > elTopOffset)
    }
  }

  useEffect(() => {
    dispatch(fetchTopbarAds.request(lang))
    window.addEventListener('scroll', handleScroll)

    return () => window.addEventListener('scroll', handleScroll)
  }, [dispatch, lang])

  useEffect(() => {
    if (visible && topbarAds && topbarAds.url && !adVisibilityTracked) {
      setAdVisibilityTracked(true)
      dispatch(topbarAdBecomeVisible(topbarAds.url))
    }
  }, [visible, dispatch, topbarAds, adVisibilityTracked])

  const handleCloseCLick = () => {
    if (history.action === 'POP') {
      history.push(lang === 'en' ? '/en' : '/')
    } else {
      history.goBack()
    }
  }

  const styleContext = [...mods, `isIn${capitalize(layout)}`, 'isInTopbar']

  const shouldRenderAd =
    lang === 'ru' && banners.top && topbarAds && topbarAds.url

  const buttons: ShareData = { share: true }

  if (layout !== 'simple' && layout !== 'book_landing' && lang === 'ru') {
    buttons.bookmark = true
  }

  if (og.pdf) {
    buttons.pdf = og.pdf
  }

  if (og.unblock) {
    buttons.unblock = og.unblock
  }

  const classNames: ClassNames = [
    [[styles.root], true],
    [[styles.stuck], visible && !!showContent]
  ]

  const context = styleContext
    .filter((ctx) => styles[ctx])
    .map((ctx) => [[styles[ctx]], true])

  classNames.push(...context)

  return (
    <div className={makeClassName(classNames)} ref={ref}>
      <div className={styles.container}>
        {showContent && (
          <>
            <div className={styles.share}>
              <ToolbarContainer
                url={url}
                lang={lang}
                place="top"
                styleContext={styleContext}
                buttons={buttons}
              />
            </div>

            {shouldRenderAd && topbarAds.url && (
              <a
                className={styles.ad}
                href={topbarAds.url}
                target="_blank"
                rel="noopener noreferrer"
                onClick={handleAdClick}
              >
                <span>{topbarAds.title}&nbsp;&nbsp;·&nbsp;&nbsp;</span>
                <span className={styles.adSource}>{topbarAds.source}</span>
              </a>
            )}
          </>
        )}
        <div
          className={styles.close}
          aria-label={
            lang === 'ru'
              ? 'Вернуться на предыдущую страницу'
              : 'Return to previous page'
          }
        >
          <button
            type="button"
            onClick={handleCloseCLick}
            className={styles.button}
          >
            <SvgSymbol icon="cross" size="medium" styleContext="isInToolbar" />
          </button>
        </div>
      </div>
    </div>
  )
}
