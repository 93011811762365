import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AudioBlock, RootState } from '@client/types'
import { EpisodeData } from '@client/types'
import { ALERT_TEXTS, TYPES, useAlert } from '@common/Alert'
import {
  getEpisodeData,
  getPlayButtonProps
} from '@selectors/audioPlayerSelectors'
import {
  requestPause,
  requestPlay
} from '@store/AudioPlayer/audioPlayerActions'
import { makeClassName } from '@utils/makeClassName'

import { SvgSymbol } from '@meduza/ui-kit-2'
import { SvgSymbolStyleContexts } from '@meduza/ui-kit-2/dist/SvgSymbol/SvgSymbol.types'

import { PlayerStatus } from '../constants'

import styles from './PlayButton.module.css'

/*
 * Универсальная кнопка Play для всех видов плееров
 */

interface PlayButtonProps {
  episodeUrl: string
  styleContext: string
  podcasts?: string
  block?: AudioBlock
}

const getEpisodeDataFromBlock = (block: AudioBlock) => ({
  audio: block.data.audio,
  player: block.data.audio.player,
  image: block.data.audio.image
})

export const PlayButton: React.FC<PlayButtonProps> = ({
  episodeUrl,
  styleContext,
  podcasts,
  block
}) => {
  const dispatch = useDispatch()
  const { show } = useAlert()

  const episode = useSelector((state: RootState) =>
    getEpisodeData(state, { url: episodeUrl, podcasts })
  )

  const { status, time } = useSelector((state: RootState) =>
    getPlayButtonProps(state, episodeUrl)
  )

  useEffect(() => {
    if (status === PlayerStatus.ERROR) {
      show(ALERT_TEXTS.ON_PLAYER_ERROR, {
        type: TYPES.ERROR
      })
    }
  }, [show, status])

  const handleClick = () => {
    if (status === PlayerStatus.LOADING) {
      return
    }

    if (status === PlayerStatus.PLAYING) {
      dispatch(requestPause({ episodeUrl }))
    } else {
      const {
        audio,
        player: { blocks },
        image
      } = episode || (block && getEpisodeDataFromBlock(block))

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const episodeData: any = { audio, blocks, image }

      dispatch(requestPlay({ episodeUrl, episodeData }))
    }
  }

  const type = status === PlayerStatus.PLAYING ? 'pause' : 'play'
  const showPlayPauseIcon = [PlayerStatus.LOADING].indexOf(status) === -1
  const showLoadingIcon = [PlayerStatus.LOADING].indexOf(status) > -1

  return (
    <button
      className={makeClassName([
        [styles.root, true],
        [styles[type], !!type && !!styles[type]],
        [styles[styleContext], !!styleContext && !!styles[styleContext]]
      ])}
      aria-label={`${type} button`}
      onClick={handleClick}
      data-unplayed={time === null || 0}
      type="button"
    >
      <span className={styles.control} tabIndex={-1}>
        {showPlayPauseIcon && (
          <SvgSymbol
            icon={type}
            size="medium"
            styleContext={styleContext as SvgSymbolStyleContexts}
          />
        )}
      </span>

      {showLoadingIcon && (
        <div className={styles.loader}>
          <svg className={styles.loaderIcon} viewBox="0 0 22 22">
            <circle
              className={styles.loaderDash}
              cx="11"
              cy="11"
              r="8"
              fill="none"
              strokeWidth="2"
            />
          </svg>
        </div>
      )}
    </button>
  )
}
