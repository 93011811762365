import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { EPISODES_PER_PAGE, EPISODES_START_PAGE } from '@client/constants'
import { EpisodesData, Lang, Pagination } from '@client/types'
import { loadMoreEpisodesPodcasts } from '@client/utils/loadMoreEpisodesPodcasts'
import { PlayButton, Progress } from '@common/AudioPlayer'
import { ProgressTypeEnums } from '@common/AudioPlayer/constants'
import { Link } from '@common/Link'

import { Button, Meta, MetaItem, SvgSymbol, Timestamp } from '@meduza/ui-kit-2'
import { ButtonStates } from '@meduza/ui-kit-2/dist/Button/Button.types'

import { EpisodesLocalization } from './EpisodesLocalization'

import styles from './Episodes.module.css'

interface EpisodesProps {
  data: EpisodesData[]
  pagination: Pagination
  lang: Lang
}

/*
 * Список эпизодов в материале одного вида подкастов
 */

export const Episodes: React.FC<EpisodesProps> = ({
  data,
  pagination,
  lang
}) => {
  const { pathname } = useLocation()
  const [page, setPage] = useState(EPISODES_START_PAGE)

  const shouldHideEpisodes = pagination.total > EPISODES_PER_PAGE * page

  const episodesList = shouldHideEpisodes
    ? data.slice(0, EPISODES_PER_PAGE)
    : data

  const [episodes, setEpisodes] = useState(episodesList)
  const [isLoadMore, setIsLoadMore] = useState(shouldHideEpisodes)
  const [state, setState] = useState<ButtonStates>('isDefault')

  const podcasts = pathname.substring(1)
  const t = EpisodesLocalization[lang]

  const handleMoreEpisodes = () => {
    loadMoreEpisodesPodcasts({
      page,
      setPage,
      episodes,
      setEpisodes,
      setState
    })
  }

  useEffect(() => {
    if (pagination.total <= EPISODES_PER_PAGE * page) {
      setIsLoadMore(false)
    }
  }, [page, pagination.total])

  return (
    <div className={styles.root}>
      <ul className={styles.list}>
        {episodes.map((episode) => (
          <li className={styles.episode} key={episode.url}>
            <Link to={`/${episode.url}`}>
              <h3>{episode.title}</h3>
              <p>{episode.subtitle}</p>
            </Link>
            <div className={styles.player}>
              <div className={styles.meta}>
                <Meta styleContext="episode">
                  <MetaItem bullets>
                    {episode.audio.mp3_duration_in_words}
                  </MetaItem>
                  <MetaItem bullets>
                    <Timestamp
                      publishedAt={episode.published_at}
                      type="fromNow"
                      locale={lang}
                    />
                  </MetaItem>
                </Meta>
              </div>
              <div className={styles.play}>
                <PlayButton
                  episodeUrl={episode.url}
                  styleContext="isInPlaylist"
                  podcasts={podcasts}
                />
                <Progress
                  episodeUrl={episode.url}
                  duration={episode.audio.mp3_duration}
                  type={ProgressTypeEnums.CIRCLE}
                  styleContext="isInPlaylist"
                />
              </div>
              <a
                className={styles.download}
                href={episode.audio.mp3_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <SvgSymbol icon="download" size="unset" />
              </a>
            </div>
          </li>
        ))}
      </ul>

      {isLoadMore && (
        <Button state={state} onClick={handleMoreEpisodes}>
          {t.more}
        </Button>
      )}
    </div>
  )
}
