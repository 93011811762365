import React, { useRef } from 'react'
import { CARD_PARAMS } from '@client/constants'
import { Block, CardsMaterialContent } from '@client/types'
import { BookmarkIcon } from '@common/BookmarkIcon'
import { useLightbox } from '@common/Lightbox'
import { RenderBlocks } from '@common/RenderBlocks'

import styles from './CardMaterial.module.css'

export interface CardMaterialProps {
  material: CardsMaterialContent
}

export const CardMaterial: React.FC<CardMaterialProps> = ({
  material: {
    image,
    url,
    content: { head, cards, table_of_contents: toc }
  }
}) => {
  const cardsRef = useRef<(HTMLDivElement | null)[]>([])
  const lightBox = useLightbox()

  const handleChapterClick = (e: React.SyntheticEvent) => {
    const index = Number(e.currentTarget.getAttribute('data-index'))
    const cardEl = cardsRef.current && cardsRef.current[index]

    if (cardEl) {
      const chapterRect = cardEl.getBoundingClientRect()
      const scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop
      const targetY =
        scrollTop +
        chapterRect.top -
        CARD_PARAMS.TOP_BAR_HEIGHT -
        CARD_PARAMS.GAP

      window.scroll({
        top: targetY,
        behavior: 'smooth'
      })
    }
  }

  /* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
  const renderTOCItem = (item: string, index: number) => (
    <li
      key={`toc${index}`}
      className={styles.navItem}
      dangerouslySetInnerHTML={{
        __html: item
      }}
      data-index={index}
      onClick={handleChapterClick}
    />
  )
  /* eslint-enable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */

  const renderCard = (card: { blocks: Block[] }, index: number) => {
    return (
      <div
        key={`card${index}`}
        ref={(el) => {
          cardsRef.current[index] = el
        }}
        className={styles.card}
      >
        {card.blocks.map((block) => (
          <RenderBlocks
            url={url}
            key={block.id}
            block={block}
            styleContext={['card', 'isInCard']}
          />
        ))}
      </div>
    )
  }

  return (
    <div className={styles.root}>
      <div className={styles.head}>
        <div className={styles.image}>
          {image && 'large_url' in image && (
            <img src={image.large_url} alt="" />
          )}
        </div>

        <div className={styles.header}>
          {head.map((block) => (
            <RenderBlocks
              url={url}
              key={block.id}
              block={block}
              lightBox={lightBox}
              styleContext={['card', 'isInCard', 'light']}
            />
          ))}
          <div className={styles.bookmark}>
            <BookmarkIcon url={url} />
          </div>
        </div>
      </div>

      <div className={styles.body}>
        <div className={styles.aside}>
          <ul className={styles.nav}>{toc && toc.map(renderTOCItem)}</ul>
        </div>

        <div className={styles.main}>
          <div className={styles.content}>{cards && cards.map(renderCard)}</div>
        </div>
      </div>
    </div>
  )
}
