import { RootState } from '@client/types'

export const getApp = (state: RootState) => state.app
export const getIsMobile = (state: RootState) => state.app.isMobile
export const getExchangeRates = (state: RootState) => state.app.exchangeRates
export const getBanners = (state: RootState) => state.app.banners
export const getAppScreenViewType = (state: RootState) =>
  state.app.screen_view_type
export const getIsFetchInitiated = (state: RootState) =>
  state.app.isFetchInitiated
