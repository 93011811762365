import { EpisodesData } from '@client/types'

import { ButtonStates } from '@meduza/ui-kit-2/dist/Button/Button.types'

interface loadMoreEpisodesPodcastsParams {
  page: number
  setPage: React.Dispatch<React.SetStateAction<number>>
  episodes: EpisodesData[]
  setEpisodes: React.Dispatch<React.SetStateAction<EpisodesData[]>>
  setState: React.Dispatch<React.SetStateAction<ButtonStates>>
}

// Функция для загрузки дополнительных эпизодов подкастов при пагинации
export const loadMoreEpisodesPodcasts = async ({
  page,
  setPage,
  episodes,
  setEpisodes,
  setState
}: loadMoreEpisodesPodcastsParams) => {
  try {
    const url = `${window.location.origin}/api/w5${
      window.location.pathname
    }/episodes/${page + 1}`
    setState('isLoading')
    const response = await fetch(url)
    const result = await response.json()
    const resultEpisodes = [...episodes, ...result.episodes]
    setEpisodes(resultEpisodes)
    setPage((prev) => prev + 1)
    setState('isDefault')
  } catch (error) {
    console.error(error)
    setState('isDefault')
  }
}
