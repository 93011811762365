import React, { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DFPBanner } from '@client/components/Common/DFPBanner'
import { NativeBanner } from '@client/components/Common/NativeBanner'
import { BannerTypes, DFPCodes } from '@client/constants'
import { getBanners } from '@client/selectors/appSelectors'
import { getCurrentMaterialBanners } from '@client/selectors/materialsSelectors'
import { fetchBanners } from '@client/store/App/appActions'
import { BannersData } from '@client/types'

interface BannerContainerProps {
  lang: string
  isMaterial?: boolean
}

export const BannerContainerComponent = ({
  lang,
  isMaterial
}: BannerContainerProps) => {
  const bannersAvailable = useSelector(getCurrentMaterialBanners)
  const bannersData: BannersData | null = useSelector(getBanners)

  const dispatch = useDispatch()

  const withBanners =
    isMaterial && bannersAvailable ? bannersAvailable.top : true

  const emergencyDisableDFP = true
  const shouldRenderDFP = !emergencyDisableDFP && lang === 'en' && withBanners

  const banners = bannersData && bannersData[lang]

  useEffect(() => {
    if (!bannersData && !shouldRenderDFP) {
      dispatch(fetchBanners.request())
    }
  }, [bannersData, shouldRenderDFP, dispatch])

  if (shouldRenderDFP) {
    return (
      <DFPBanner
        type={BannerTypes.TOP}
        id={DFPCodes.DESKTOP_AFT}
        mobileId={DFPCodes.MOBILE_TOP_BANNER}
      />
    )
  }

  return <NativeBanner items={banners} />
}

export const BannerContainer = memo(BannerContainerComponent)

BannerContainer.whyDidYouRender = true
