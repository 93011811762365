import {
  BACKOFF_FACTOR,
  INITIAL_RECONNECT_DELAY,
  MAX_RECONNECT_DELAY,
  RANDOM_DELAY_INTERVAL
} from '@client/constants'
import { LiveMaterialContent, MaterialContent } from '@client/types'
import {
  getMaterialLive,
  getMaterialsById
} from '@selectors/materialsSelectors'
import { MaterialLiveState } from '@store/Materials/liveReducer'
import {
  connectLive,
  disconnectLive,
  fetchMaterial,
  fetchTopbarAds,
  fetchUnblockLink,
  liveInitialItems,
  liveNewItems,
  liveUpdateItems
} from '@store/Materials/materialsActions'
import { handleError } from '@utils/handleError'
import { EventChannel } from 'redux-saga'
import {
  all,
  call,
  delay,
  put,
  select,
  take,
  takeEvery
} from 'redux-saga/effects'
import { LiveResponse } from 'types/apiTypes'

import * as api from '../api'
import * as sockets from '../sockets'

/*
 Запрос за данными материала в апи
 */

export function* fetchMaterialSaga({
  payload
}: ReturnType<typeof fetchMaterial.request>) {
  try {
    const response = yield call(api.fetchMaterial, payload)
    yield put(fetchMaterial.success({ response }))
  } catch (err) {
    yield call(handleError, err, fetchMaterial.failure.type)
    yield put(fetchMaterial.failure())
  }
}

/*
 Запрос за данными рекламы для верхней плашки материала
 */

export function* fetchTopbarAdsSaga({
  payload
}: ReturnType<typeof fetchTopbarAds.request>) {
  try {
    const response = yield call(api.fetchTopbarAds, payload)
    yield put(fetchTopbarAds.success({ response }))
  } catch (err) {
    yield call(handleError, err, fetchTopbarAds.failure.type)
    yield put(fetchTopbarAds.failure())
  }
}

/*
 Live: запрос на соединение с сокетом и создание канала
 */

export function* connectLiveSaga({
  payload: url
}: ReturnType<typeof connectLive.request>) {
  const materials: Record<string, MaterialContent> = yield select(
    getMaterialsById
  )
  const document = materials[url]
  const {
    content: {
      broadcast: { socket_url, channel }
    }
  } = document as LiveMaterialContent

  const socketChannel: EventChannel<unknown> = yield call(
    sockets.createWebSocketConnection,
    socket_url
  )

  yield call(watchSocketChannel, socketChannel, url, channel)
}

/*
 Live: листенер канала сокета
 */

export function* watchSocketChannel(
  socketChannel: EventChannel<unknown>,
  url: string,
  channel: string
) {
  let currentReconnectDelay = INITIAL_RECONNECT_DELAY

  while (true) {
    const { message } = yield take(socketChannel)
    if (message === 'open') {
      currentReconnectDelay = INITIAL_RECONNECT_DELAY

      yield put(connectLive.success(url))

      const newChannel = yield call(sockets.createSocketChannel, channel)
      yield call(watchChannel, url, newChannel)
    } else if (message === 'fail' || message === 'close') {
      yield call(
        handleError,
        new Error(`socket channel connect fail: ${url}`),
        'SOCKET_CHANNEL_CONNECT_FAIL'
      )

      // Exponential Backoff

      const randomDelay =
        currentReconnectDelay +
        Math.floor(Math.random() * RANDOM_DELAY_INTERVAL)

      const returnedDelay = yield delay(randomDelay, currentReconnectDelay)

      if (returnedDelay < MAX_RECONNECT_DELAY) {
        currentReconnectDelay *= BACKOFF_FACTOR
        yield call(sockets.reconnect)
      } else {
        yield call(
          handleError,
          new Error(`socket_error: ${url}`),
          'SOCKET_ERROR_AFTER_EXPONENTIAL_BACKOFF'
        )
        yield put(connectLive.failure(url))
      }
    }
  }
}

/*
 Live: листенер phoenix-socket канала
 */

export function* watchChannel(url: string, channel: EventChannel<unknown>) {
  while (true) {
    const {
      message,
      data
    }: { message: string; data: LiveResponse['data'] } = yield take(channel)

    const materialLive: MaterialLiveState = yield select(getMaterialLive)

    const live = materialLive.byId[url]

    if (message === 'ok') {
      const newItemsIds = data.items_ids.filter(
        (id: string) => live.items_ids.indexOf(id) === -1
      )

      yield put(
        liveInitialItems({
          url,
          items_ids: newItemsIds,
          items: data.items
        })
      )
    }

    if (message === 'create') {
      if (live.new_items_ids_pending.indexOf(data.items_ids[0]) === -1) {
        yield put(
          liveNewItems({
            url,
            items_ids: data.items_ids,
            items: data.items
          })
        )
      }
    }

    if (message === 'update') {
      yield put(
        liveUpdateItems({
          url,
          items: data.items
        })
      )
    }
  }
}

/*
 Live: отключение всех каналов
 */

export function* disconnectLiveSaga({
  payload: url
}: ReturnType<typeof disconnectLive.request>) {
  sockets.closeConnection()
  yield put(disconnectLive.success(url))
}

/*
Запрашивает не заблокированную ссылку на материал
*/
export function* fetchUnblockLinkSaga({}: ReturnType<
  typeof fetchUnblockLink.request
>) {
  try {
    const response = { data: { url: ' ' } }
    yield put(fetchUnblockLink.success(response))
  } catch (err) {
    yield call(handleError, err, fetchUnblockLink.failure.type)
    yield put(fetchUnblockLink.failure())
  }
}

export default function* appSaga() {
  yield all([
    takeEvery(fetchMaterial.request.type, fetchMaterialSaga),
    takeEvery(fetchTopbarAds.request.type, fetchTopbarAdsSaga),
    takeEvery(fetchUnblockLink.request.type, fetchUnblockLinkSaga),

    takeEvery(connectLive.request.type, connectLiveSaga),
    takeEvery(disconnectLive.request.type, disconnectLiveSaga)
  ])
}
